import * as React from 'react';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import IconPDF from '@material-ui/icons/PictureAsPdf';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';

import { solvencyTop10 } from './client/Solvenzquoten-TOP-10-Lebensversicherer-2021'
import { solvency2023 } from './client/Solvency-II-Quoten-2023-SFCR-Marktfuehrer'
import { solvency2022 } from './client/Solvency-II-Quoten-2022-SFCR-Marktfuehrer'
import { solvency2021 } from './client/Solvenzquoten-Lebensversicherer-2021'
import { solvency2020 } from './client/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2020'
import { solvency2019 } from './client/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2019'
import { solvency2018 } from './client/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2018'
import { solvency2016 } from './client/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2016'
import { useState } from 'react';

const SolvencyIIClientPage: React.FC = () => {

    const [content, setContent] = useState(solvency2023)
    const [year, setYear] = useState('2023')

    const [pdfFile, setPdfFile] = useState('/solvency/Solvency-II-Quoten-2023-SFCR-Marktfuehrer.pdf')

    const yearsStyle: React.CSSProperties = {
        display: 'flex'
    }

    const iframeStyle: React.CSSProperties = {
        height: '100%',
        width: '100%'
    }

    const yearBtnStyle: React.CSSProperties = {
        padding: '5px',
        border: '1px solid #A7893D',
        borderRadius: '4px',
        margin: '10px',
        cursor: 'pointer',
        color: '#A7893D'
    }

    const yearBtnSelectedStyle: React.CSSProperties = {
        backgroundColor: '#A7893D',
        color: 'white'
    }

    const yearsArr = [
        // {
        //     year: 'Top10',
        //     pdf: solvencyTop10,
        //     file: '/solvency/Solvenzquoten-TOP-10-Lebensversicherer-2021.pdf'
        // },
        {
            year: '2023',
            pdf: solvency2023,
            file: '/solvency/Solvency-II-Quoten-2023-SFCR-Marktfuehrer.pdf'
        },
        {
            year: '2022',
            pdf: solvency2022,
            file: '/solvency/Solvency-II-Quoten-2022-SFCR-Marktfuehrer.pdf'
        },
        {
            year: '2021',
            pdf: solvency2021,
            file: '/solvency/Solvenzquoten-Lebensversicherer-2021.pdf'
        },
        {
            year: '2020',
            pdf: solvency2020,
            file: '/solvency/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2020.pdf'
        },
        {
            year: '2019',
            pdf: solvency2019,
            file: '/solvency/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2019.pdf'
        },
        {
            year: '2018',
            pdf: solvency2018,
            file: '/solvency/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2018.pdf'
        },
        {
            year: '2016',
            pdf: solvency2016,
            file: '/solvency/Solvenzquoten-der-wichtigsten-Deutschen-Lebensversicherer-2016.pdf'
        }
    ]

    return (
        <NavigateBackScaffold
            title={'Solvency II für Kundenberatung'}
            actions={<></>}
        >
            <div style={{ height: 'calc(100vh - 170px)' }}>
                <Typography variant={'body1'} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <div style={{
                        fontWeight: 'bold',
                        padding: '5px',
                        margin: '10px',
                        paddingRight: '10px'
                    }}>Jahr</div>
                    <div style={yearsStyle}>
                        {
                            yearsArr.map((y, k) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setContent(y.pdf)
                                            setYear(y.year)
                                            setPdfFile(y.file)
                                        }}
                                        style={{
                                            ...yearBtnStyle,
                                            ...(year === y.year) ? yearBtnSelectedStyle : {}
                                        }}
                                    >{y.year}</div>
                                )
                            })
                        }
                    </div>
                    <div style={{
                        // fontWeight: 'bold',
                        // padding: '5px',
                        margin: '5px',
                        // paddingRight: '10px'
                    }}>
                        <Tooltip title="PDF download">
                            <IconButton onClick={() => {
                                let a = document.createElement('a');
                                a.target = '_blank';
                                a.href = pdfFile;
                                a.click();
                            }}>
                                <IconPDF style={{
                                    position: 'relative',
                                    top: 0, left: 0, right: 0, bottom: 0

                                }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Typography>
                <iframe
                    width={'100%'}
                    height={'100%'}
                    frameBorder={0}
                    title='quoten'
                    srcDoc={content}
                    style={iframeStyle}
                />
            </div >

        </NavigateBackScaffold >
    );
};

export default SolvencyIIClientPage;
