import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';

const filter1 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => contract.garantierteRente === 'Ja' || parseFloat(contract.garantierteRente.toString()) > 0);

const filter2 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        const GRF = contract.garantierterRentenfaktor.toString();

        if (GRF == null || GRF.includes('GK')) {
            return false;
        } else if (parseFloat(GRF) > 0) {
            return true;
        } else {
            return GRF.includes('Ja');
        }
    });

const filter3 = (contracts: Array<InsuranceContractModel>) => contracts;

const filter4 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        return contract.besserungsoption.toString() === 'Ja';
    });
const filter5 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        return contract.beitragspause.toString() === 'Ja';
    });
const filter6 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        return !contract.verschiebungRentenbeginnCom.toString().includes('VZRB');
    });
const filter7 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        if (contract.verschiebungRentenbeginn.toString().includes('Nein')) {
            return false;
        } else if (contract.verschiebungRentenbeginn.toString().includes('?')) {
            return false;
        } else if (contract.verschiebungRentenbeginnCom.toString().includes('VSRB')) {
            return false;
        }
        return true;
    });
const filter8 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        let rkwMatches = contract.maxRgz.toString().match(/RKW/g);

        if (contract.maxRgz.toString() !== 'RKW' && rkwMatches && rkwMatches[0]) {
            return false;
        }

        let matches = contract.maxRgz.toString().match(/([+-]?\d+)([eE][+-]?\d+)?/);

        return matches && matches[0] && parseInt(matches[0]) >= 21; // >= 22
    });
const filter9 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        return contract.bu.toString().includes('Ja');
    });
const filter10 = (contracts: Array<InsuranceContractModel>) =>
    contracts.filter((contract: InsuranceContractModel) => {
        const nettoQuoteMatches = contract.solvency2Com.toString().match(/Netto-Quote: ([0-9]*)/gi);
        if (nettoQuoteMatches && nettoQuoteMatches[0]) {
            const nettoQuote = nettoQuoteMatches[0].replace(/[^0-9]/g, '');
            if (parseInt(nettoQuote) >= 250) { // >= 175
                return true;
            }
        }
        return false;
    });

export const filters = [filter1, filter2, filter3, filter4, filter5, filter6, filter7, filter8, filter9, filter10];
