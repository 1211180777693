import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import contractData from 'assets/contractData-20241203.json';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';
import { NormalizedObjects } from 'features/riesterProfiCheck/customers/customerSlice';
import { normalize } from 'normalizr';
import { schema } from 'normalizr';
import { RootState } from 'app/store';

const contractSchema = new schema.Entity<InsuranceContractModel>('insuranceContracts');
const contractArraySchema = new schema.Array<InsuranceContractModel>(contractSchema);
const initialNormalizedData = normalize(contractData, contractArraySchema);

export interface Contracts extends NormalizedObjects<InsuranceContractModel> {}

export interface ContractsState {
    entities: { [id: string]: InsuranceContractModel };
    ids: string[];
    contractIdsToCompare: string[];
    filter: ContractsFilter
}

export interface ContractsFilter {
    old: boolean,
    new: boolean
}

const initialState: ContractsState = {
    entities: initialNormalizedData.entities.insuranceContracts as { [id: string]: InsuranceContractModel },
    ids: initialNormalizedData.result,
    contractIdsToCompare: [],
    filter: {
        old: false,
        new: true
    }
};

export const contractsSlice = createSlice({
    name: 'contracts',
    initialState: initialState,
    reducers: {
        setContracts: (state: Contracts, action: PayloadAction<Contracts[]>) => {
            const normalizedData = normalize(action.payload, contractArraySchema);
            state.entities = normalizedData.entities.insuranceContracts as { [id: string]: InsuranceContractModel };
            Object.keys(normalizedData.entities);
        },
        resetContractIdsToCompare: (state: ContractsState) => {
            state.contractIdsToCompare = [];
        },
        setContractIdsToCompare: (state: ContractsState, action: PayloadAction<Array<InsuranceContractModel>>) => {
            state.contractIdsToCompare = action.payload.map((contract: InsuranceContractModel) => contract.id);
        },
        setFilter: (state: ContractsState, action: PayloadAction<ContractsFilter>) => {
            return { ...state, filter: {...state.filter, ...action.payload}};
        },
        overwriteContracts: (state: ContractsState, action: PayloadAction<ContractsState>) => {
            state.contractIdsToCompare = action.payload.contractIdsToCompare
            state.entities = action.payload.entities
            state.filter = action.payload.filter
            state.ids = action.payload.ids
        },
    }
});
export const getContracts = (state: RootState) => state.contracts;

export const getContractIdsToCompare = (state: RootState) => state.contracts.contractIdsToCompare;
export const getContractsToCompare = createSelector(
    (state: RootState) => {
        return [state.contracts.entities[state.contracts.contractIdsToCompare[0]], state.contracts.entities[state.contracts.contractIdsToCompare[1]]];
    },
    (contracts) => contracts
);

export const ContractsActions = contractsSlice.actions;
export default contractsSlice.reducer;
